<template>
  <b-card
    class="inventoryorderdetailmodify-edit-wrapper"
  >
    <ValidationObserver ref="observer">
    <!-- form -->
    <b-form id="inventoryorderdetailmodifyForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="盘点单编号"
            label-for="inventory_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inventory_no"
              size="sm"
              disabled
              v-model="inventoryorderdetailmodify.inventory_no"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="名称"
              label-for="name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="name"
                size="sm"
                v-model="currentProductName"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              disabled
              v-model="currentProductCode"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              disabled
              v-model="currentProductSpecification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity"
              size="sm"
              disabled
              v-model="currentProductBoxQuantity"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存选择"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >

            <ValidationProvider :rules="required" name="库存必选" #default="{ errors }">

            <b-form-input
                id="stock_id"
                size="sm"
                @click="showStockWin($event)"
                v-model="locationFullName"
                readonly
                :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="操作类型"
            label-for="change_type"
            label-size="sm"
            class="mb-1"
          >
<!--            <b-form-input
              id="change_type"
              size="sm"
              v-model="inventoryorderdetailmodify.change_type"
            />-->
            <ValidationProvider :rules="required" name="操作类型必选" #default="{ errors }">
              <v-select
                  id="option_type"
                  :options="optionType"
                  :clearable="false"
                  v-model = "changeTypeValue"
                  class="select-size-sm"
                  @input="changeType($event)"
                  :state="errors.length > 0 ? false:null"
                  :reduce="option => option.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调整数量"
            label-for="qty"
            label-size="sm"
            class="mb-1 required"
          >
            <ValidationProvider :rules="required" name="数量" #default="{ errors }">
            <b-form-input
                id="qty"
                size="sm"
                v-model="tzQty"
                :state="errors.length > 0 ? false:null"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1 required"
          >
            <ValidationProvider :rules="required" name="备注" #default="{ errors }">
              <b-form-input
                id="memo"
                size="sm"
                v-model="inventoryorderdetailmodify.memo"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1 "
          >
              <attachment-upload  v-if="inventoryorderdetailmodify.loaded"
                                  :theme="'files'"
                                  :attachment_id="'attachments'"
                                  :id="inventoryorderdetailmodify.attachments"
                                  :object_type="'inventoryorderdetailmodify'"
                                  :object_id="inventoryorderdetailmodify.modify_id"
                                  @change="onUploaded"
              />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>

          <b-button
              variant="primary"
              class="mr-2"
              @click="saveAndSubmit"
          >
            保存并提交审核
          </b-button>

          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectWHFromPeople"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'"
        ref="fromWHModal"
    >
      <WareHouse-Select
          ref="whSelect" v-on:table="fromChildren($event,'from')"
      >
      </WareHouse-Select>
    </b-modal>

    <b-modal
        id="stockModal"
        ok-only
        ok-title="确认"
        @ok="onSelectStock"
        cancel-title="取消"
        centered
        size="xl"
        :title="'请选择'"
        ref="stockWinModal"
    >
      <stock-list
          ref="stockSelect" :warehouseid="currentWarehouseId" :productid="currentProductId">
      </stock-list>
    </b-modal>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import inventoryorderdetailmodifyStore from './inventoryorderdetailmodifyStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import StockList from "@/views/apps/stock/stock-inventoryorder/StockList";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    WareHouseSelect,
    StockList,
    AttachmentUpload,
  },
  props: {
    inventoryNo: {
      type: String,
      default: '',
    },
    inventoryId: {
      type: Number,
      default: 0
    },
    true_qty: {
      type: Number,
      default: 0
    },
    inventoryDetailid: {
      type: Number,
      default: 0
    },
    warehouseId: {
      type: Number,
      default: 0
    },
    productId: {
      type: Number,
      default: 0
    },
    productName: {
      type: String,
      default: ''
    },
    productCode: {
      type: String,
      default: ''
    },
    productSpecification: {
      type: String,
      default: ''
    },
    productBoxQuantity: {
      type: Number,
      default: 0
    },
    detailData: {
      type: Object,
    }
  },
  data() {
    return {
      id: ref(0),
      inventoryorderdetailmodify: ref({}),
      warehouseName: '',
      stockList: [],
      optionType: [{"label": "增加", "value": 1}, {"label": "减少", "value": 2}],
      //changeTypeValue: '',
      stockIdValue: '',
      required: "required",
      locationFullName: "",

      // currentInventoryNo: this.inventoryNo,
      currentInventoryId: this.inventoryId,
      currentInventoryDetailid: this.detailData != undefined? this.detailData.inventory_detailid: 0,
      currentWarehouseId: this.warehouseId,
      currentProductId: this.detailData != undefined? this.detailData.product_id:0,
      currentProductName: this.detailData != undefined? this.detailData.name:"",
      currentProductCode:this.detailData != undefined?  this.detailData.code:"",
      currentProductSpecification: this.detailData != undefined? this.detailData.specification: "",
      currentProductBoxQuantity: this.detailData != undefined? this.detailData.box_quantity:"",
      //tzQty: this.detailData != undefined? Math.abs((this.detailData.true_qty-this.detailData.total_qty)):"",
      tzQty: this.detailData!=undefined ?(this.detailData.true_qty != undefined ? Math.abs((this.detailData.true_qty-this.detailData.total_qty)):""):"",
      //changeTypeValue: this.detailData != undefined? (this.detailData.true_qty-this.detailData.total_qty):"",
      changeTypeValue:this.detailData!=undefined ?(this.detailData.true_qty != undefined?((this.detailData.true_qty-this.detailData.total_qty)>=0 ? "增加":"减少"):""):"",
    }
  },
  methods: {
    showFromWHModal(){
      this.$refs['fromWHModal'].show()
    },
    onSelectWHFromPeople(){
      let returnData = {}
      if(this.$refs.whSelect.getSelected().length > 0){
        returnData = this.$refs.whSelect.getSelected()[0]
        this.inventoryorderdetailmodify.warehouse_id = returnData.id
        this.warehouseName = returnData.warehouse_name
        this.$forceUpdate()
      }
    }
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorderdetailmodify')) store.registerModule('inventoryorderdetailmodify', inventoryorderdetailmodifyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetailmodify')) store.unregisterModule('inventoryorderdetailmodify')
    })

    const edit = async function() {
      this.detailData = props.detailData;
      await store.dispatch('inventoryorderdetailmodify/edit', {id: this.id}).then(res => {
        if (this.id != 0) {
          this.inventoryorderdetailmodify = res.data.data
          //this.currentInventoryNo = this.inventoryorderdetailmodify.inventory_no
          this.currentInventoryId = this.inventoryorderdetailmodify.inventory_id
          this.currentInventoryDetailid = this.inventoryorderdetailmodify.inventory_detailid
          this.currentWarehouseId = this.inventoryorderdetailmodify.warehouse_id
          this.currentProductId = this.inventoryorderdetailmodify.product_id
          this.currentProductName = this.inventoryorderdetailmodify.name
          this.currentProductCode = this.inventoryorderdetailmodify.code
          this.currentProductSpecification = this.inventoryorderdetailmodify.specification
          this.currentProductBoxQuantity = this.inventoryorderdetailmodify.box_quantity
          this.tzQty =  this.inventoryorderdetailmodify.qty
          this.changeTypeValue=(this.inventoryorderdetailmodify.true_qty-this.inventoryorderdetailmodify.total_qty)>=0 ? "增加":"减少"
          this.locationFullName = this.inventoryorderdetailmodify.stock_id
          //this.inventoryorderdetailmodify.change_type = this.changeTypeValue
          for (let i = 0; i < this.optionType.length; i++) {
            if (this.optionType[i].value == this.inventoryorderdetailmodify.change_type) {
              this.changeTypeValue = this.optionType[i].label
            }
          }
        }else {
          this.inventoryorderdetailmodify = res.data.data
          this.inventoryorderdetailmodify.inventory_no = this.inventoryNo
        }
      })
    }

    const view = function() {
      store.dispatch('inventoryorderdetailmodify/view', {id: this.id}).then(res => {
        this.inventoryorderdetailmodify = res.data.data
      })
    }

    const cancel = function() {
      if (this.id === 0) {
        this.$bvModal.hide('inventoryOrderDetailModify')
      }else {
        this.$router.go(-1)
      }
    }

    const save = async function() {
      // this.warehouseId
      if (this.inventoryorderdetailmodify.status === 2) {
          toast.error("此订单在审核中，不允许保存！")
          return
      } else {
        let validate = await this.$refs.observer.validate()

        if (validate) {
          this.inventoryorderdetailmodify.status = 1
          this.inventoryorderdetailmodify.inventory_detailid = this.currentInventoryDetailid
          this.inventoryorderdetailmodify.inventory_id = this.currentInventoryId
          this.inventoryorderdetailmodify.warehouse_id = this.currentWarehouseId
          this.inventoryorderdetailmodify.code = this.currentProductCode
          this.inventoryorderdetailmodify.qty= this.tzQty
          //this.inventoryorderdetailmodify.change_type = (this.detailData.true_qty-this.detailData.total_qty)>0?'1':'2'
          this.inventoryorderdetailmodify.change_type = (this.inventoryorderdetailmodify.true_qty-this.inventoryorderdetailmodify.total_qty)>0?'1':'2'
          await axios.post('/api/inventoryorderdetailmodify/save', this.inventoryorderdetailmodify).then(res => {
            if (res.data.code === 0) {
              toast.success('数据已保存!')
              this.$bvModal.hide('inventoryOrderDetailModify')
            }else {
              toast.error(res.data.data)
            }
          })
        } else {
          toast.error("请按照规范填写内容!")
          return
        }
      }
    }

    const saveAndSubmit = async function () {
      if (this.inventoryorderdetailmodify.status === 2) {
        toast.error("此订单在审核中，不允许保存！")
        return
      }
      let validate = await this.$refs.observer.validate()

      if (validate) {
        this.inventoryorderdetailmodify.status = 2
        this.inventoryorderdetailmodify.inventory_detailid = this.currentInventoryDetailid
        this.inventoryorderdetailmodify.inventory_id = this.currentInventoryId
        this.inventoryorderdetailmodify.warehouse_id = this.currentWarehouseId
        this.inventoryorderdetailmodify.code = this.currentProductCode
        this.inventoryorderdetailmodify.qty= this.tzQty
        //this.inventoryorderdetailmodify.change_type = (this.detailData.true_qty-this.detailData.total_qty)>0?'1':'2'
        this.inventoryorderdetailmodify.change_type = (this.inventoryorderdetailmodify.true_qty-this.inventoryorderdetailmodify.total_qty)>0?'1':'2'
        axios.post('/api/inventoryorderdetailmodify/save', this.inventoryorderdetailmodify).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            this.$bvModal.hide('inventoryOrderDetailModify')
          }else {
            toast.error(res.data.data)
          }
        })
      } else {
        toast.error("请按照规范填写内容!")
        return
      }

    }

    const showStock = function() {
      if (this.inventoryorderdetailmodify.warehouse_id === undefined) {
        toast.error("请先选择仓库！")
        return
      }
      this.$bvModal.show('stockModal')
    }

    const onSelectStock = async function (){
      let returnData = {}
      if(this.$refs.stockSelect.getChecked().length > 0){

        returnData = this.$refs.stockSelect.getChecked()
        this.$set(this.inventoryorderdetailmodify,"name", returnData[0].name)
        this.$set(this.inventoryorderdetailmodify,"product_id", returnData[0].product_id)
        await axios.post("/api/product/findProductByProductId", {product_id: returnData[0].product_id})
        .then(res => {
          if (res.data.code === 1) {
            toast.success("商品查询失败，请重试！")
            return
          }

          this.$set(this.inventoryorderdetailmodify,"code", this.productCode)
          this.$set(this.inventoryorderdetailmodify,"warehouse_id", this.warehouseId)
          this.$set(this.inventoryorderdetailmodify,"specification", this.productSpecification)
          this.$set(this.inventoryorderdetailmodify,"box_quantity", this.productBoxQuantity)
          this.$set(this.inventoryorderdetailmodify,"stock_id", returnData[0].stock_id)
          this.$set(this.inventoryorderdetailmodify,"stock_status", returnData[0].status)

          this.locationFullName = returnData[0].location_fullname

          axios.get("/api/stock/findStockByWhIdAndPId", {params:{
              warehouse_id: this.currentWarehouseId,
              product_id: this.currentProductId
            }}).then(res => {
            if (res.data.code === 1) {
              toast.success("库存查询失败，请重试！")
              return
            }
            let list = res.data.data
            for (let i = 0; i < list.length; i++) {
              let obj = {}
              obj.label = list[i].name
              obj.value = list[i].stock_id
              obj.status = list[i].status
              this.stockList.push(obj)
            }
          })
        })
      }
    }

    const btKeyUp = function(e) {
      var reg = RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g);
      if(e.target.value.match(reg)){
        toast.error("包含特殊字符，入库数量自动取整!")
           e.target.value = e.target.value.substring(0, e.target.value.indexOf("."))
        return
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.inventoryorderdetailmodify[id] = result
    }

    const changeType = function(event) {
      this.inventoryorderdetailmodify.change_type = event.value
    }

    const changeStockId = function(event) {
      this.inventoryorderdetailmodify.stock_id = event.value
      this.inventoryorderdetailmodify.stock_status = event.status
    }

    const showStockWin = function(event) {
      this.$refs['stockWinModal'].show()
    }

    return {
      edit,
      view,
      cancel,
      save,
      saveAndSubmit,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onSelectStock,
      showStock,
      btKeyUp,
      onUploaded,
      changeType,
      changeStockId,
      showStockWin,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
