import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function inventoryorderdetailUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'inventory_id', label: '盘点编号Id', sortable: true },
    // { key: 'is_inventory', label: '参与', sortable: true },
    // { key: 'stock_id', label: '库存ID', sortable: true },
    // { key: 'operator', label: '盘点人', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'code', label: '商品编码(69码)'},
    { key: 'name', label: '商品名称'},
    { key: 'specification', label: '商品规格' },
    { key: 'box_quantity', label: '单箱标准数量' },
    { key: 'total_qty', label: '总数量'},
    { key: 'locked_qty', label: '占用数量' },
    //{ key: 'true_qty', label: '实际盘点数量' },
    //{ key: 'inbound_total_qty', label: '入库总数量' },
   // { key: 'outbound_total_qty', label: '出库总数量' },
   // { key: 'outbound_total_qty', label: '调整后总数量', sortable: true },
    // { key: 'true_qty', label: '实际盘点数量', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    // { key: 'location_fullname', label: '仓位', sortable: true },
    // { key: 'total_qty', label: '数量', sortable: true },
    { key: 'status', label: '库存状态' },
    //{ key: 'memo', label: '备注' },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    // { key: 'updator', label: '修改人', sortable: true },
        // { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {

    store
      .dispatch('inventoryorderdetail/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        inventory_id:config.inventory_id,
        //isRTL:config.isRTL,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
        for (let i = 0; i < list.length; i++) {
          if (list[i].true_qty != undefined) {
            list[i].judge_true_qty = 0
          }else {
            list[i].judge_true_qty = 1
          }
        }

        console.log("list: ",list )

      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
