<template>
  <b-card
      style="width:100%"
  >
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >

        <b-row>

<!--  盘点编号  -->
          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="盘点编号:"
                label-for="name"
                label-size="sm"
            >
              <b-form-input
                  v-model="condition.inventOrderNo"
                  class="d-inline-block mr-1"
                  size="sm"
              />
            </b-form-group>
          </b-col>

<!--  盘点类型        -->
           <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="盘点类型:"
                label-for="status_id"
                label-size="sm"
            >
              <v-select
                  id="status_id"
                  :options="getCodeOptions('inventory_type')"
                  :clearable="true"
                  v-model = "condition.statusLabel"
                  class="select-size-sm"
                  placeholder="请选择盘点类型"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
<!--  盘点时段        -->
           <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="盘点时段:"
                label-for="receipt_begin_time"
                label-size="sm"
            >
              <flat-pickr
                  v-model="condition.receipt_begin_time"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
<!--   仓库 -->
          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库:"
                label-for="warehouse_name"
                label-size="sm"
            >
              <b-form-input
                  id="warehouse_name"
                  size="sm"
                  v-model="condition.warehousename"
                  @click="showSingleModal('仓库')"
                  readonly
                  placeholder="点击搜索仓库"
              />
            </b-form-group>
          </b-col>
<!--   商品 -->
          <b-col
              cols="12"
              md="3"
          >
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="商品名称:"
                label-for="product_name"
                label-size="sm"
            >
              <b-form-input
                  id="product_name"
                  size="sm"
                  v-model="condition.productName"
                  @click="showSingleModal('商品')"
                  readonly
                  placeholder="点击选择商品"
              />
            </b-form-group>
          </b-col>


          <b-col
              cols="12"
              md="3"
          >
            <b-button
                variant="outline-primary"
                type="submit"
                class="mr-1"
            >
              <span class="align-right">查询</span>
            </b-button>

            <b-button
                variant="outline-primary"
                type="reset"
            >
              <span class="align-right">重置</span>
            </b-button>

          </b-col>
        </b-row>
    </b-form>


    <!--弹出框头部和尾部-->
    <b-modal
        id="modal-select-warehouse"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <WareHouse-Select
          ref="whSelect" v-if="modalName === '仓库'  "
      >
      </WareHouse-Select>

      <!--:可加入 判断所属部门 userDepartment="userDepartment"-->
      <product-select
          ref="prodSelect"  v-if="modalName === '商品'  "
      >
      </product-select>

    </b-modal>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRef, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
/**/
import inventoryorderUseList from './inventoryorderUseList'
import inventoryorderStore from './inventoryorderStore'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'

export default {
  components: {
    WareHouseSelect,
    ProductSelect,
    CompanySelect
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0,
    },
    role:{
      type:Object,
    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    // Register module
    if (!store.hasModule('inventoryorder')) store.registerModule('inventoryorder', inventoryorderStore)

    onUnmounted(() => {
      if (store.hasModule('inventoryorder')) store.unregisterModule('inventoryorder')
    })

    onMounted(() => {
      state.condition = store.getters['inventoryorder/getCondition']
    })

    const state = reactive({
      modalName: '',
      condition: {
      },
      singleModal: null,
    })
    const methods = {
      showSingleModal(modalName) {
        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '商品': {
            data = this.$refs.prodSelect.getSelected()

            if(data.length > 0){
              state.condition.productName = data[0].name;
              state.condition.productId = data[0].product_id;
            }
            break
          }
          case '仓库': {
            data = this.$refs.whSelect.getSelected()
            if(data.length > 0){
              state.condition.warehousename = data[0].warehouse_name;
              state.condition.warehouseId = data[0].warehouse_id;
            }
            break
          }
        }
      },
      refreshTable  (){
        const  receipt_begin_time = state.condition.receipt_begin_time
        emit('refreshTable')
      },

      resetCondition() {
        store.commit('inventoryorder/resetCondition')
        methods.refreshTable()
      },
    }

    return {
      ...toRefs(state),
      ...methods,

      //filter
      getCodeOptions,
    }
  },
}
</script>

<style scoped>

</style>
