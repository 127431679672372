import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function inventoryorderUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'inventory_id', label: 'ID', sortable: true },
    { key: 'inventory_no', label: '盘点编号', sortable: true },
    { key: 'company_id', label: '仓库方', sortable: true },
    { key: 'warehouse_id', label: '仓库', sortable: true },
    { key: 'type', label: '盘点类型', sortable: true },
    { key: 'start_time', label: '盘点开始时间', sortable: true },
    { key: 'end_time', label: '盘点结束时间', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    //{ key: 'memo', label: '备注'},
    //{ key: 'attachments', label: '附件'},
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'create_time', label: '发起时间', sortable: true },
    // { key: 'status', label: '盘点状态', sortable: true },
    { key: 'actions', label: '操作' },
    // { key: 'excel', label: '导出' },
    // { key: 'excelData', label: '导出数据' },
    // { key: 'exportExcelData', label: '导入数据' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('inventory_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['inventoryorder/getCondition']}
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':''
    }
    Object.assign(params,condition)
    store
      .dispatch('inventoryorder/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
