<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!--search-->
          <search @refreshTable=refetchData ></search>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-inventoryorder-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- 库存盘点详情 -->
        <template #row-details="data">
          <InventoryOrderUseDetailList
              :inventory_id="data.item.inventory_id"
              :inventory_no="data.item.inventory_no"
              :warehouse_id="data.item.warehouse_id"></InventoryOrderUseDetailList>
        </template>


        <!-- Columns -->
        <template #cell(inventory_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button
          >
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
<!--          <b-link-->
<!--              :to="{ name: 'apps-inventoryorder-edit', query: { id: data.item.id } }"-->
<!--              class="font-weight-bold d-block text-nowrap"-->
<!--          >-->
            #{{ data.item.inventory_id }}
<!--          </b-link>-->
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ getCodeLabel('inventory_type', data.item.type) }}
        </template>

        <template #cell(company_id)="data">
          {{ getCodeLabel('company', data.item.company_id) }}
        </template>

        <template #cell(warehouse_id)="data">
          {{ getCodeLabel('warehouse', data.item.warehouse_id) }}
        </template>

        <template #cell(start_time)="data">
          {{ toTime(data.item.start_time) }}
        </template>

        <template #cell(end_time)="data">
          {{ toTime(data.item.end_time) }}
        </template>

        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <template #cell(status)="data">
          {{ getCodeLabel('inventory_status', data.item.status) }}
        </template>

        <template #cell(memo)="data">
          <div :id="`remark-row-${data.item.inventory_id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon v-if="data.value" icon="EditIcon" @click="showRemark(data.item)"/>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.inventory_id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span variant="primary"
                    size="sm"
                    @click="setLockQty(data.item)" class="align-middle ml-50">更新锁库数量</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailmodify-list', query: { inventoryId: data.item.inventory_id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">库存调整申请列表</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span @click="createExcel(data.item)" class="align-middle ml-50">导出excel(不带数量)</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span @click="createExcelData(data.item)" class="align-middle ml-50">导出excel(带数量)</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span variant="primary"
                    size="sm"
                    v-b-modal.modal-stock-log
                    @click="changeData(data.item)" class="align-middle ml-50">导入数据</span>
            </b-dropdown-item>




          </b-dropdown>
        </template>


      </b-table>

      <b-modal
              id="modal-stock-log"
              centered
              size="sm"
              title="导入数据"
              hide-footer="true"
              >
        <b-row>
        <b-col md="12">
          <b-form-group
                  label-cols="2"
                  label-cols-lg="2"
                  label-for="attachments_ids"
                  label="附件"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :object_type="'excel'"
                               @change="onUploadedExcel"
            />
          </b-form-group>
        </b-col>
        <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
          >
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportExcel"
                    v-if="showOr"
            >
              <span class="align-right">导入</span>
            </b-button>

          </b-col>
        </b-row>
      </b-modal>

      <b-modal
          id="modal"
          ok-only
          ok-title="确认"
          @ok="endInventory"
          cancel-title="取消"
          centered
          size="lg"
          title="相关信息"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="备注"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="memo"
                  size="sm"
                  v-model="memo"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="相关附件ids"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="attachments"
                                 :object_type="'inventory_order'"
                                 :object_id="inventory_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-modal>

      <b-modal
          id="modal-remark"
          ok-only
          ok-title="确认"
          @ok="saveRemark"
          cancel-title="取消"
          centered
          size="lg"
          title="修改备注"
          ref="remarkModal"
      >
        <b-card :header="`编号：${selectedItem.inventory_no}`">
          <b-form-textarea
              id="textarea"
              v-model="selectedItem.memo"
              rows="3"
              max-rows="6"
          />
        </b-card>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import inventoryorderUseList from './inventoryorderUseList'
import inventoryorderStore from './inventoryorderStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useToast} from "vue-toastification/composition";
import InventoryOrderUseDetailList from "@/views/apps/inventoryorderdetail/InventoryOrderUseDetailList";
import Search from "@/views/apps/inventoryorder/Search";
import XyLoading from "@/views/components/xy/XyLoading";
export default {
  components: {
    InventoryOrderUseDetailList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    Search,
    XyLoading
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('inventoryorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    showRemark(item) {
      this.selectedItem = item
      this.$refs['remarkModal'].show()
    },
  },
  data(){
    return{
      inventory_id:0,
      memo:"",
      attachments:"",
      selectedItem: {},
      excelSqlId: '',
      excelData: '',
      showOr:true,
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!"
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('inventoryorder')) store.registerModule('inventoryorder', inventoryorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorder')) store.unregisterModule('inventoryorder')
    })

    const startInventory = function (params) {
      if (confirm("点击“确定”按钮，开始盘点，当前仓库将限制出入库操作?")){
        store.dispatch('inventoryorder/changeStatus', {inventoryid: params.inventory_id,type:2}).then(res => {
          if (res.data==="success"){
            toast.success("开始成功!")
            refetchData()
          }else {
            toast.error("开始失败!")
            refetchData()
          }
        })
      }
    }

    const endInventory = function () {
      if (confirm("点击“确定”按钮，完成盘点，当前仓库解锁出入库操作，确认要执行此操作吗?")){
        store.dispatch('inventoryorder/changeStatus', {inventoryid:this.inventory_id,type:3,memo:this.memo,attachments:this.attachments}).then(res => {
          if (res.data==="success"){
            toast.success("完成!")

            refetchData()
          }else {
            toast.error("失败!")
            refetchData()
          }
        })
      }
    }

    const setId = function (params) {
      this.inventory_id = params.inventory_id
    }

    const changeData = function (val) {
      this.excelData = val.inventory_id
    }

    const onUploaded = function (id, attachment, result) {
      this.attachments = result
    }

    const onUploadedExcel = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const exportExcel = function () {
      this.showOr = false
      const params = {
        excelSqlId: this.excelSqlId,
        excelData: this.excelData,
      }
        store.dispatch('inventoryorder/exportExcel', params)
                .then(response => {
                  const data = response.data.data
                  if (response.data.code == 0) {
                    toast.success("导入成功")
                    refetchData();
                  } else {
                    toast.error(response.data.data)
                  }
                })
      }


    const saveRemark = function () {
      store.dispatch('inventoryorder/save', this.selectedItem).then(res => {
        if (res.data.code === 0) {
          toast.success("修改成功")
        } else {
          toast.error("修改失败")
        }
      })
    }

    const createExcel = function (params) {
       store.dispatch('inventoryorder/createExcel', {inventoryid:params.inventory_id}).then(res => {
         let wareHouseName =  getCodeLabel('warehouse',params.warehouse_id)
         let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
         let filename = wareHouseName+"盘点单-"+params.inventory_no;

         /*-------------*/
         const link = document.createElement('a')

         link.style.display = 'none'
         link.href = URL.createObjectURL(data);
         link.setAttribute('download',filename+'.xlsx')
         document.body.appendChild(link)
         link.click()
         document.body.removeChild(link)

       })


       }

    const createExcelData = function (params) {
      store.dispatch('inventoryorder/createExcelData', {inventoryid:params.inventory_id}).then(res => {
        let wareHouseName =  getCodeLabel('warehouse',params.warehouse_id)
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = wareHouseName+"盘点单-"+params.inventory_no;

        /*-------------*/
        const link = document.createElement('a')

        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      })


    }

    const setLockQty = function (params) {
      this.loadingModal = true
      store.dispatch('inventoryorder/setLockQty', {inventory_id:params.inventory_id,warehouse_id:params.warehouse_id}).then(res => {
        this.loadingModal = false
        if (res.data.code === 0) {
          toast.success("更新成功")
        } else {
          toast.error(res.data.data)
        }
      })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = inventoryorderUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      onUploadedExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      startInventory,
      endInventory,
      setId,
      onUploaded,
      saveRemark,
      createExcel,
      createExcelData,
      exportExcel,
      changeData,
      setLockQty
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
