<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input-->
<!--                v-model="searchKey"-->
<!--                class="d-inline-block mr-1"-->
<!--                placeholder="搜索关键字..."-->
<!--              />-->
<!--              <b-button-->
<!--                variant="primary"-->
<!--                :to="{ name: 'apps-inventoryorderdetail-edit'}"-->
<!--              >-->
<!--                <span class="text-nowrap">添加</span>-->
<!--              </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="Columns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="tableArray"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-inventoryorderdetail-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(operator)="data">
          <b-form-input
              id="inventory_no"
              size="sm"
              v-model="data.item.operator"
              @change="toParent"
          />
        </template>

        <template #cell(is_inventory)="data">
          <div>
            <span v-if="data.item.is_inventory === 0">
              不参与盘点
            </span>
            <span v-else>
              参与盘点
            </span>
          </div>
        </template>

        <template #cell(true_qty)="data">
          <span v-if="data.item.judge_true_qty === 1" >
              <b-form-input
                  type="number"
                  size="sm"
                  @keyup.native="btKeyUp"
                  v-model="data.item.true_qty"
                  style="width: 60%; float: left"
              >
              </b-form-input>
              <b-link>
                <feather-icon
                    icon="SaveIcon"
                    size="16"
                    class="align-middle text-body"
                    @click="saveTrueQty(data.item)"
                />
              </b-link>
          </span>
          <span v-else>
             <b-form-input
                 type="number"
                 size="sm"
                 @keyup.native="btKeyUp"
                 v-model="data.item.true_qty"
                 disabled
                 style="width: 60%;"
             >
              </b-form-input>
          </span>
        </template>

        <template #cell(status)="data" style="float: right">
          {{getCodeLabel("stock_status",data.item.status)}}
        </template>

        <template #cell(after_modify_qty)="data" style="float: right">

            <span v-if="data.item.outbound_total_qty == null&&data.item.inbound_total_qty==null">
                {{data.item.total_qty}}
            </span>

          <span v-if="data.item.outbound_total_qty != null&&data.item.inbound_total_qty!=null">
                {{data.item.total_qty+data.item.inbound_total_qty-data.item.outbound_total_qty}}
          </span>

          <span v-if="data.item.outbound_total_qty == null&&data.item.inbound_total_qty!=null">
                {{data.item.total_qty+data.item.inbound_total_qty}}
          </span>

          <span v-if="data.item.outbound_total_qty != null&&data.item.inbound_total_qty==null">
                {{data.item.total_qty-data.item.outbound_total_qty}}
          </span>


        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-inventoryorderdetailextend-list', query: { inventory_detailid: data.item.inventory_detailid } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">出入库明细</span>
            </b-dropdown-item>
<!--            <b-dropdown-item @click="inventoryOrderModify(data.item)">-->
<!--              <feather-icon icon="EditIcon"/>-->
<!--              <span class="align-middle ml-50">申请库存调整</span>-->
<!--            </b-dropdown-item>-->
          </b-dropdown>

          <!-- 添加对话框 -->
          <b-modal
              id="inventoryOrderDetailModify"
              ok-title="确认"
              cancel-title="取消"
              centered
              size="xl"
              title="盘点单明细_库存调整申请单窗口"
              ref="inventoryOrderDetailModify"
              hide-footer
          >

            <InventoryOrderDetailModifyEdit
                :inventoryNo="inventory_no"
                :inventoryId="data.item.inventory_id"
                :inventoryDetailid="data.item.inventory_detailid"
                :warehouseId="warehouse_id"
                :productId="data.item.product_id"
                :productName="data.item.name"
                :productCode="data.item.code"
                :productSpecification="data.item.specification"
                :productBoxQuantity="data.item.box_quantity"
                :true_qty="data.item.true_qty"
                :detailData="detailData"></InventoryOrderDetailModifyEdit>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import inventoryorderdetailUseList from './inventoryorderdetailUseList'
import inventoryorderdetailStore from './inventoryorderdetailStore'
import Button from "@/views/components/button/Button";
import {useToast} from "vue-toastification/composition";
import InventoryOrderDetailModifyEdit from "@/views/apps/inventoryorderdetailmodify/InventoryOrderDetailModifyEdit";
import axios from "@/libs/axios";

export default {
  components: {
    Button,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    InventoryOrderDetailModifyEdit,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('inventoryorderdetail/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data(){
    return{
      tableArray:[],
      Columns:[
        // { key: 'id', label: 'ID', sortable: true },
        // { key: 'inventory_id', label: '盘点编号Id', sortable: true },
        { key: 'is_inventory', label: '参与', sortable: true },
        // { key: 'stock_id', label: '库存ID', sortable: true },
        // { key: 'operator', label: '盘点人', sortable: true },
        // { key: 'product_id', label: '商品ID', sortable: true },
        { key: 'code', label: '商品编码(69码)', sortable: true },
        { key: 'name', label: '商品名称', sortable: true },
        { key: 'specification', label: '商品规格', sortable: true },
        { key: 'box_quantity', label: '单箱标准数量', sortable: true },
        { key: 'total_qty', label: '总数量', sortable: true },
        { key: 'locked_qty', label: '占用库存' },
        { key: 'inbound_total_qty', label: '入库调整数', sortable: true ,headerTitle:'未做单子的入库数量' },
        { key: 'outbound_total_qty', label: '出库调整数', sortable: true,headerTitle:'未做单子的出库数量' },
        { key: 'after_modify_qty', label: '调整后总数量', sortable: true,headerTitle:'总数量+入库调整数-出库调整数' },
        { key: 'true_qty', label: '实际盘点数量', sortable: true },
        // { key: 'true_qty', label: '实际盘点数量', sortable: true },
        // { key: 'batch_no', label: '产品批次', sortable: true },
        // { key: 'production_date', label: '生产日期', sortable: true },
        // { key: 'expiry_date', label: '有效日期', sortable: true },
        // { key: 'location_fullname', label: '仓位', sortable: true },
        // { key: 'total_qty', label: '数量', sortable: true },
        { key: 'status', label: '库存状态', sortable: true },
        { key: 'memo', label: '备注', sortable: true },
        // { key: 'add_time', label: '添加时间', sortable: true },
        // { key: 'creator', label: '添加人', sortable: true },
        // { key: 'modify_time', label: '修改时间', sortable: true },
        // { key: 'updator', label: '修改人', sortable: true },
        { key: 'actions', label: '操作' },
      ],
      roleId: '',
      detailData: {}
    }
  },
  props: {
    inventory_id: {
      type: Number
    },
    inventory_no: {
      type: String
    },
    warehouse_id: {
      type: Number
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('inventoryorderdetail')) store.registerModule('inventoryorderdetail', inventoryorderdetailStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetail')) store.unregisterModule('inventoryorderdetail')
    })

    let toast = useToast()

    const toParent = function () {
      this.$emit("table",this.tableArray)
    }

    const saveTrueQty = function(item) {
      axios.post("/api/inventoryorderdetail/save", item).then(res => {
        if (res.data.code === 0) {
          toast.success("保存实际盘点数量成功!")
          this.refetchData()
        }else {
          toast.error("保存实际盘点数量失败!")
        }
      })
    }

    const btKeyUp = function(e) {
      var reg = RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g);
      if(e.target.value.match(reg)){
        toast.success("包含特殊字符，入库数量自动取整!")
        // e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
        e.target.value = e.target.value.substring(0, e.target.value.indexOf("."))
      }
    }

    const inventoryOrderModify = function(e) {
      this.detailData = e
      this.$refs['inventoryOrderDetailModify'].show()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = inventoryorderdetailUseList(
        {
          inventory_id: props.inventory_id
        }
       )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      saveTrueQty,
      btKeyUp,
      inventoryOrderModify,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
      // UI
      toParent,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
