<template>

  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <b-button
                  variant="primary"
              >
                <span class="text-nowrap">搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchListByWhIdAndPid"
          responsive
          hover
          small
          :fields="tableColumn"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="alldata"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(stock_id)="data">
            #{{ data.item.stock_id }}
        </template>

        <template #cell(stocklock_id)="data">
          <v-select
              id="stocklock_id"
              :options="data.item.temp1"
              :clearable="false"
              class="select-size-sm"
              @input="getStockId($event)"
              :value="data.item.temp1SL"
              v-model="data.item.temp1SL"
          />
        </template>

        <template #cell(available_cost)="data">
          {{data.item.cost}}
          <v-select
              id="available_cost"
              :options="data.item.temp2"
              :clearable="false"
              class="select-size-sm hidden"
              @input="getPurchaseOrderItemId($event)"
              :value="data.item.temp2SL"
              v-model="data.item.temp2SL"
          />
        </template>

        <template #cell(company_id)="data">
          {{ getCodeLabel("company", data.item.company_id)}}
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}{{ changeIsGit(data.item.is_gift) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '库存编号：'+data.item.stock_no+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(status)="data">
<!--          <span :class="'text-'+getCode('stock_status', data.item.status).color">{{ getCodeLabel("stock_status", data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status)}}
          </b-badge>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <!-- Column: Type -->
        <template #cell(stock_type)="data">
          {{ resolveStockTypeCode(data.item.stock_type).label }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${resolveStateCode(data.item.state).color}`"

            >
              {{ resolveStateCode(data.item.state).label }}
            </b-badge>
          </b-link>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,BIcon, BootstrapVueIcons,
   VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCodeLabel, toDate,getCodeColor} from '@core/utils/filter'
import stockUseList from "@/views/apps/stock/stockUseList";
import stockStore from '@/views/apps/stock/stockStore'
import stockinbounditemStore from "@/views/apps/stockinbounditem/stockinbounditemStore";
import stocklockStore from "@/views/apps/stocklock/stocklockStore";
import stocklock from "@/router/routes/apps/stocklock";
import Ripple from 'vue-ripple-directive'
import {getCode} from "@core/utils/filter";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checked: [],
      salesorderitem: ref({}),
      alldata: [],
      rowItem: ref({}),
      tableColumn: [
        { key: 'stock_id', label: 'ID', sortable: true },
        { key: 'warehouse_name', label: '仓库', sortable: true },
        { key: 'company_id', label: '货主', sortable: true },
        { key: 'name', label: '商品', sortable: true },
        { key: 'qty', label: '数量', sortable: true },
        { key: 'cost', label: '成本', sortable: true },
        { key: 'location_fullname', label: '仓位名称', sortable: true },
        { key: 'status', label: '状态', sortable: true },
      ]
    }
  },
  props: {
    isshowcost: {
      type: Number,
      default: 1
    },
    warehouseid:{
      type:Number,
      default:0,
    },
    companyId:{
      type:Number,
      default:0,
    },
    basicStoreId: {
      type:Number,
      default:0,
    },
    productid: {
      type: Number,
    }
  },
  created() {
    this.warehouseIdVal = this.warehouseid
    this.productIdVal = this.productid
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stock/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    getChecked: function () {
      return this.rowItem
    },
    getStockId: function (res) {
      for (let i = 0; i < this.alldata.length; i++) {
        if (this.alldata[i].stock_id == res.stock_id) {
          this.alldata[i].stocklock_id = res.stocklock_id;
          if (this.alldata[i].purchaseorderitem_id == undefined) {
            this.alldata[i].purchaseorderitem_id = 0
          }
        }
      }
    },
    getPurchaseOrderItemId: function (res) {
      for (let i = 0; i < this.alldata.length; i++) {

        if (this.alldata[i].stock_id == res.stock_id) {
          this.alldata[i].purchaseorderitem_id = res.purchaseorderitem_id;
          if (this.alldata[i].stocklock_id == undefined) {
            this.alldata[i].stocklock_id = 0
          }
        }
      }
    }

  },
  setup(props) {
    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)
    if (!store.hasModule('stockinbounditem')) store.registerModule('stockinbounditem', stockinbounditemStore)
    if (!store.hasModule('stocklock')) store.registerModule('stocklock', stocklockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
      if (store.hasModule('stockinbounditem')) store.unregisterModule('stockinbounditem')
      if (store.hasModule('stocklock')) store.unregisterModule('stocklock')
    })

    const changeIsGit = function (res) {
      if (res === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const onRowSelected = function(item) {
      this.rowItem = item
    }

    const {
      searchList,
      searchListAllInfo,
      searchListByWhIdAndPid,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      warehouseIdVal,
      productIdVal,

      // UI
      resolveStockTypeCode,
      resolveStateCode,
    } = stockUseList({
      isshowcost: props.isshowcost,
      warehouseid:props.warehouseid,
      companyId:props.companyId,
      basicStoreId: props.basicStoreId,
      // order_id : props.order_id
    })

    return {
      searchList,
      searchListAllInfo,
      searchListByWhIdAndPid,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      warehouseIdVal,
      productIdVal,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,

      // UI
      resolveStockTypeCode,
      resolveStateCode,
      changeIsGit,
      toDate,
      onRowSelected,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
